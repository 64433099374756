import cx from 'classnames'
import styles from './style.module.scss'

export type CardVariant =
    | 'strong'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'border'
    | 'secondary-gray'
    | 'primary-aqua'

type Props = {
    className?: string
    variant?: CardVariant
    onClick?: () => void
    height?: number | string
    width?: number | string
    noInset?: boolean
    noBottomMargin?: boolean
    children: React.ReactNode
    key?: string
}

export const Card = ({
    className,
    variant,
    onClick,
    height,
    width,
    noInset,
    noBottomMargin,
    ...props
}: Props) => {
    return (
        <div
            className={cx(
                [styles.card],
                {
                    'inset-md': !noInset,
                    'stacked-md': !noBottomMargin,
                    [styles.clickable]: onClick,
                    [styles.strong]: variant === 'strong',
                    [styles.primary]: variant === 'primary',
                    [styles.secondary]: variant === 'secondary',
                    [styles.success]: variant === 'success',
                    [styles.warning]: variant === 'warning',
                    [styles.danger]: variant === 'danger',
                    [styles.border]: variant === 'border',
                    [styles.secondaryGray]: variant === 'secondary-gray',
                    [styles.primaryAqua]: variant === 'primary-aqua',
                },
                className
            )}
            onClick={onClick}
            {...props}
            style={{ height, width }}
        />
    )
}
