import 'react-toastify/dist/ReactToastify.css'
import './styles.scss'

import posthog from 'posthog-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'

import Script from 'next/script'
import { useRouter } from 'next/router'

import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import { PostHogProvider } from 'posthog-js/react'

import ErrorBoundary from 'lib/common/ErrorBoundary'
import { useCheckDarkModePreference } from 'lib/hooks/useColorTheme'

const isProduction = process.env.NODE_ENV === 'production'
const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

if (typeof window !== 'undefined' && posthogKey && posthogHost) {
    // checks that we are client-side
    posthog.init(posthogKey, {
        api_host: posthogHost,
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: { password: true },
        },
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
        },
    })
}

function MyApp({ Component, pageProps }) {
    const router = useRouter()

    useCheckDarkModePreference()

    return (
        <div className="h-100p">
            <QueryClientProvider client={queryClient}>
                <Head>
                    <title>Savio - Administración de Cuentas por Cobrar</title>
                    <meta
                        name="description"
                        content="Savio - Administración de Cuentas por Cobrar"
                    />
                    <meta
                        name="keywords"
                        content="Savio - Administración de Cuentas por Cobrar"
                    />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    />
                    <link rel="icon" href="/images/favicon.png" />
                </Head>
                {isProduction ? (
                    <>
                        <Script
                            src="https://www.googletagmanager.com/gtag/js?id=G-Q9JK42C7NN"
                            strategy="afterInteractive"
                        />
                        <Script id="ga4" strategy="afterInteractive">
                            {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        
                        gtag('config', 'G-Q9JK42C7NN');`}
                        </Script>
                        <script async type="text/javascript" src="/js/newrelic.js" />
                    </>
                ) : null}
                <PostHogProvider client={posthog}>
                    <NuqsAdapter>
                        <ErrorBoundary key={router.pathname}>
                            <Component {...pageProps} />
                        </ErrorBoundary>
                    </NuqsAdapter>
                </PostHogProvider>

                <div id="modal-root"></div>
                <div id="syncfy-widget"></div>

                <ToastContainer
                    toastClassName="toast-container"
                    position="top-center"
                    hideProgressBar
                />
                <Script
                    async
                    type="module"
                    src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
                />
                <Script
                    async
                    noModule
                    src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
                />
                {isProduction ? null : <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        </div>
    )
}

export default MyApp
