import { forwardRef, DetailedHTMLProps, ButtonHTMLAttributes, ReactNode } from 'react'
import { Icon, Props as IconProps } from '../Icon'
import cx from 'classnames'
import styles from './style.module.scss'
import { LinkController } from '../LinkController'
import { hyphenToPascalCase } from 'lib/utils/hyphenToPascalCase'
import { Tooltip } from '../Tooltip'

export type ButtonVariant =
    | 'primary'
    | 'primarySubtle'
    | 'primaryText'
    | 'secondary'
    | 'secondaryText'
    | 'success'
    | 'successText'
    | 'danger'
    | 'dangerSubtle'
    | 'dangerText'
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface ButtonProps
    extends Omit<
        DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
        'onChange' | 'ref'
    > {
    variant?: ButtonVariant
    href?: string
    size?: ButtonSize
    isLoading?: boolean | string
    fullWidth?: boolean
    left?: boolean
    icon?: string
    iconRight?: string
    round?: boolean
    iconProps?: Omit<IconProps, 'icon'>
    tooltipContent?: ReactNode
    readOnly?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            href,
            className,
            variant,
            size,
            disabled,
            isLoading,
            onClick,
            fullWidth,
            left,
            children,
            icon,
            iconRight,
            round,
            iconProps,
            tooltipContent,
            ...props
        },
        ref
    ) => {
        const isDisabled = !!disabled || !!isLoading
        const button = (
            <button
                className={cx(styles.btn, 'ease', className, {
                    [styles.disabled]: isDisabled,
                    [styles.xs]: size === 'xs',
                    [styles.sm]: size === 'sm',
                    [styles.md]: !size || size === 'md',
                    [styles.lg]: size === 'lg',
                    [styles.xl]: size === 'xl',
                    [styles.primary]: !variant || variant === 'primary',
                    [styles.primarySubtle]: variant === 'primarySubtle',
                    [styles.primaryText]: variant === 'primaryText',
                    [styles.secondary]: variant === 'secondary',
                    [styles.secondaryText]: variant === 'secondaryText',
                    [styles.success]: variant === 'success',
                    [styles.successText]: variant === 'successText',
                    [styles.danger]: variant === 'danger',
                    [styles.dangerSubtle]: variant === 'dangerSubtle',
                    [styles.dangerText]: variant === 'dangerText',
                    [styles.fullWidth]: fullWidth,
                    [styles.left]: left,
                    [styles.round]: round,
                })}
                disabled={isDisabled}
                ref={ref}
                onClick={isDisabled ? () => false : onClick}
                {...props}
            >
                <div
                    className={cx(
                        'd-flex',
                        'align-items-center',
                        left ? 'justify-content-start' : 'justify-content-center'
                    )}
                >
                    {icon ? (
                        <Icon
                            icon={hyphenToPascalCase(icon)}
                            className={cx({ 'inline-sm': !!children })}
                            {...iconProps}
                        />
                    ) : null}
                    {children ? (
                        <div className={cx({ 'inline-sm': iconRight })}>
                            {isLoading ? isLoading : children}
                        </div>
                    ) : null}
                    {iconRight ? <Icon icon={hyphenToPascalCase(iconRight)} /> : null}
                </div>
            </button>
        )

        if (href) {
            return (
                <LinkController
                    className={cx({ [styles.fullWidth]: fullWidth })}
                    href={href}
                >
                    {button}
                </LinkController>
            )
        } else if (tooltipContent) {
            return (
                <Tooltip removeUnderline disableClick offset={12} trigger={button}>
                    {tooltipContent}
                </Tooltip>
            )
        }
        return button
    }
)

Button.displayName = 'Button'
